import React from "react";
import { Box, Stack, Text, useBreakpointValue } from "@chakra-ui/react";
import { LoyaltyTable } from "./LoyaltyTable";

export const Loyalty = () => {
  return (
    <Box bg="bg.surface">
      <Stack spacing="5">
        {/* @ts-ignore */}
        <Box pb={{ base: 5, md: 0 }} overflowX={{ base: "auto", md: "none" }}>
          <LoyaltyTable />
          <Text
            color="#737373"
            fontSize="sm"
            fontWeight={400}
            lineHeight="120%"
            pl={{ base: 0, md: 4 }}
            pt={5}
          >
            *Tier levels are based on the total purchases made within the
            calendar year, not on the historical purchase total.
          </Text>
          <Text
            color="#737373"
            fontSize="sm"
            fontWeight={400}
            lineHeight="120%"
            pl={{ base: 0, md: 4 }}
            pt={5}
          >
            **The discount will be applied to your next order.
          </Text>
        </Box>
      </Stack>
    </Box>
  );
};
