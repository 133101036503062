import React from "react";
import {
  Box,
  Heading,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  VStack,
  Text,
} from "@chakra-ui/react";
import faq from "./faq";

export const LoyaltyFAQ = () => {
  return (
    <>
      <Box
        py={{ base: 10, md: 20 }}
        px={{ base: 4, md: 0 }}
        w={{ base: "343px", md: "1280px" }}
        mx={{ base: 0, md: "auto" }}
      >
        <VStack alignItems="flex-start">
          <Box bg="#B97230" width="24px" height="2px"></Box>
          <Heading
            pt={6}
            pb={10}
            fontSize={{ base: "30px !important", md: "36px !important" }}
            fontWeight={400}
          >
            FAQ
          </Heading>
        </VStack>
        <Accordion
          allowToggle
          border="none"
          w={{ base: "343px", md: "100%" }}
          defaultIndex={[0]}
        >
          {faq.map((item, index) => {
            return (
              <AccordionItem
                key={index}
                border="none"
                borderBottom="1px solid #E1E7EF"
              >
                <AccordionButton
                  textAlign="left"
                  border="none"
                  py={4}
                  px={{ base: 0, md: 4 }}
                  justifyContent={"space-between"}
                  _hover={{ background: "transparent" }}
                >
                  <Text
                    color="#1A212D"
                    fontSize="md"
                    fontWeight={600}
                    lineHeight="24px"
                  >
                    {item.question}
                  </Text>
                  <AccordionIcon width="1rem" height="1rem" color="#DDA572" />
                </AccordionButton>
                <AccordionPanel px={{ base: 0, md: 4 }}>
                  <Text
                    color="#595959"
                    fontSize="sm"
                    fontWeight={400}
                    lineHeight="24px"
                  >
                    {item.answer}
                  </Text>
                </AccordionPanel>
              </AccordionItem>
            );
          })}
        </Accordion>
      </Box>
    </>
  );
};
