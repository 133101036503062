export const benefits = [
  {
    id: "1",
    name: "Tiers*",
    silver: "Annual Purchase Total",
    silverammount: "$1 - $20,000",
    gold: "Annual Purchase Total",
    goldammount: "$20,001 - $50,000",
    platinum: "Annual Purchase Total",
    platinumammount: "$50,001+",
  },
  {
    id: "2",
    name: "Points Per Amount Spent",
    silver: "$1 = $1 Point",
    gold: "$1 = 2 Points",
    platinum: "$1 = 3 Points",
  },
  {
    id: "3",
    name: "Anniversary Discount**",
    silver: "5% Discount",
    gold: "7% Discount",
    platinum: "9% Discount",
  },
  {
    id: "4",
    name: "Anniversary Gift",
    silver: "x",
    gold: "x",
    platinum: "Free Favorite Product",
  },
];
