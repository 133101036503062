import {
	Box,
	Flex,
	Button,
	HStack,
	Heading,
	Link,
	Text,
	VStack,
	Image,
} from '@chakra-ui/react';
import React, { FunctionComponent } from 'react';

import HeroContainer from '../components/Containers/HeroContainer';
import Layout from '../components/Base/Layout';
import { Loyalty } from '../components/Loyalty';
import { LoyaltyFAQ } from '../components/Loyalty/LoyaltyFaq';
import { SEO } from '../components/Base/Seo';

const LoyaltyProgram: FunctionComponent = () => {
	return (
		<Layout>
			<HeroContainer
				height='460px'
				is404={false}
				backgroundPosition={{ md: 'right', '2xl': 'top !important' }}
				heroSpan={'EARN WITH EVERY PURCHASE'}
				heroTitle={
					<Text>
						Welcome to Doctor Medica's{' '}
						<Text color='#DCA672'>Loyalty Program</Text>
					</Text>
				}
				heroSubTitle='Effortlessly earn points and redeem them at will. Unlock exclusive rewards with every new membership tier. Benefit from up to 3% cashback on every purchase, enjoy discounts of up to 9%, receive personalized gifts, and beyond.'
				heroImage={{
					base: '/heroBackgroundM.webp',
					md: '/heroBackground.webp',
				}}
				arrowImage='/remixiconslinesystemarrowdownsline2.svg'
			/>
			<Box
				my={{ base: 10, md: 20 }}
				mx={{ base: 4, md: 'auto' }}
				id='anchorlink'
				maxW='1280px'
				borderRadius='16px'>
				<VStack alignItems='flex-start'>
					<HStack
						flexFlow={{ base: 'column', lg: 'row' }}
						gap='40px'
						alignItems='center'>
						<Box w='100%'>
							<Box
								bg='#B97230'
								width='24px'
								height='2px'></Box>
							<Text
								textTransform='capitalize'
								color='#120B0C'
								fontSize={{ base: '30px', md: '36px' }}
								fontWeight='400'
								py='20px'
								lineHeight={'150%'}>
								Membership Levels Unlock as You Shop,
								<Text color='#D28C49'>Revealing New Benefits</Text>
							</Text>
						</Box>
					</HStack>
					<Box
						w='100%'
						overflow='visible'>
						<Loyalty />
					</Box>
				</VStack>
			</Box>
			<Box
				px='20px'
				pt={{ base: '40px', md: '0' }}
				backgroundImage='/unlock-savings.webp'
				backgroundRepeat='no-repeat'
				backgroundSize='cover'
				h={{ base: '622px', md: '480px' }}>
				<Box
					maxW='1280px'
					m='0px auto'>
					<HStack
						justifyContent='space-around'
						flexFlow={{ base: 'column', md: 'row' }}>
						<VStack alignItems='flex-start'>
							<Box
								bg='#120B0C'
								width='24px'
								height='2px'></Box>
							<Heading
								w={{ base: '343px', md: '600px' }}
								pt={6}
								fontSize={{ base: '30px !important', md: '36px' }}
								fontWeight={400}>
								Transform Your Points Into Savings {''}
								<Text
									as='span'
									color='#D28C49'>
									{' '}
									With Ease
								</Text>
							</Heading>
							<Text
								fontSize='md'
								fontWeight={400}
								color='#262626'
								pb={{ base: 6, md: 8 }}
								lineHeight='150%'>
								Every 100 points translates to $1 you can use for purchases with
								Doctor Medica. Our Loyalty Program ensures that the more you
								buy, the more you benefit. Sign up today to turn your shopping
								into earning.
							</Text>
							<Link
								href='/sign-in'
								w={{ base: '100%', md: '224px' }}>
								<Button
									fontSize='sm'
									fontWeight={700}
									color='#120B0C'
									variant='outline'
									textTransform={'uppercase'}>
									Unlock Savings Now
								</Button>
							</Link>
						</VStack>
						<Image
							src='/loyalty-offer.webp'
							alt='Loyalty Offer Women'
							w={{ base: '343px', md: '600px' }}
							h={{ base: '260px', md: '480px' }}
						/>
					</HStack>
				</Box>
			</Box>
			<LoyaltyFAQ />
		</Layout>
	);
};

export const Head = () => (
	<SEO
		title='Loyalty Program | DoctorMedica'
		description="Doctor Medica's Loyalty Program - up to 3% cashback on every purchase, enjoy discounts of up to 9%, receive personalized gifts, and more."
	/>
);

export default LoyaltyProgram;
