import * as React from "react";

import {
  Box,
  Button,
  HStack,
  Heading,
  Image,
  Link,
  Text,
  VStack,
  background,
} from "@chakra-ui/react";
import CSS, { Property } from "csstype";
import { FunctionComponent, useMemo } from "react";

import { AnchorLink } from "gatsby-plugin-anchor-links";

type HeroContainerType = {
  height: string;
  heroImage?: any;
  heroSubTitle: string;
  heroTitle?: any;
  heroSpan?: string;
  backgroundPosition?: any;
  sectionDescription?: string;
  arrowImage?: string;
  url?: string;
  button?: boolean;
  buttonHref?: string;
  buttonText?: string;
  is404: boolean;
  top?: string;

  /** Style props */
  heroBackgroundPadding?: Property.Padding;
};

const HeroContainer: FunctionComponent<HeroContainerType> = ({
  height,
  heroImage,
  heroSubTitle,
  heroTitle,
  heroSpan,
  button,
  buttonHref,
  buttonText,
  backgroundPosition,
  sectionDescription,
  arrowImage,
  heroBackgroundPadding,
  is404,
  top,
  url,
}) => {
  const heroSectionStyle: CSS.Properties = useMemo(() => {
    return {
      padding: heroBackgroundPadding,
    };
  }, [heroBackgroundPadding]);
  const path = typeof window !== "undefined" ? window.location.pathname : "";

  return (
    <>
      <Box
        background="linear-gradient(180deg, transparent 43.14%, rgba(0, 0, 0, 0.8)),
    rgba(18, 11, 12, 0.5)"
        backgroundImage={heroImage}
        backgroundPosition={backgroundPosition}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        height={height}
        w="100%"
      >
        {/* OVERLAY */}
        <Box
          className="overlay"
          background="linear-gradient(180deg, transparent 43.14%, rgba(0, 0, 0, 0.8)),
    rgba(18, 11, 12, 0.5)"
          zIndex="1"
          position="absolute"
          margin="0!important"
          height="inherit"
          width="100%"
          top="0"
          right="0"
          bottom="0.13rem"
          left="0"
        />
        <VStack
          position="relative"
          top={{ base: top, md: 0 }}
          zIndex="2"
          justifyContent="center"
          alignItems="center"
          height="100%"
          color="white"
          px="20px"
          gap="20px"
          maxW="800px"
          mx="auto"
        >
          <Text
            as="span"
            color="white"
            fontWeight={500}
            fontSize="20px"
            lineHeight="120%"
          >
            {heroSpan}
          </Text>
          <Heading
            as="h1"
            fontWeight="400"
            lineHeight="58px"
            fontSize={{ base: "36px !important", md: "48px !important" }}
            textAlign="center"
          >
            {heroTitle}
          </Heading>
          <Text
            textAlign="center"
            fontSize="16px"
            fontWeight="400"
            lineHeight="24px"
          >
            {heroSubTitle}
          </Text>

          {button && (
            <Link href={buttonHref}>
              <Button
                variant="outline"
                color="white"
                colorScheme="white"
                border="1px solid white"
              >
                {buttonText}
              </Button>
            </Link>
          )}

          {is404 ? (
            <></>
          ) : (
            <Box position="absolute" bottom="2.63rem">
              <AnchorLink to={path + "#anchorlink"}>
                <Image
                  position="relative"
                  top={{ base: "32px", md: 0 }}
                  w="2.5rem"
                  h="2.5rem"
                  overflow="hidden"
                  alt="Arrow Down"
                  src={arrowImage}
                />
              </AnchorLink>
            </Box>
          )}
        </VStack>
      </Box>
    </>
  );
};

export default HeroContainer;
