import React from "react";
import {
  Avatar,
  Badge,
  Box,
  Checkbox,
  HStack,
  Icon,
  IconButton,
  Table,
  TableProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

import { benefits } from "./data";

export const LoyaltyTable = (props: TableProps) => (
  <Table {...props}>
    <>
      <Thead bg="#F8F1F0" h={20}>
        <Tr>
          <Th pr={0}>
            <HStack w={{ base: "163px", md: "264px" }} h="35px" py={2}></HStack>
          </Th>
          <Th px={{ base: 1, md:  6 }}>
            <HStack w={{ base: "163px", md: "264px" }} bg="#E6BF9A" h="35px" py={2} borderRadius="8px">
              <Text
                color="white"
                fontSize="md"
                fontWeight={700}
                textAlign="center"
                mx="auto"
              >
                SILVER
              </Text>
            </HStack>
          </Th>
          <Th px={{ base: 1, md:  6 }}>
            <HStack w={{ base: "163px", md: "264px" }} bg="#DCA672" h="35px" py={2} borderRadius="8px">
              <Text
                color="white"
                fontSize="md"
                fontWeight={700}
                textAlign="center"
                mx="auto"
              >
                GOLD
              </Text>
            </HStack>
          </Th>
          <Th px={{ base: 1, md:  6 }}>
            <HStack w={{ base: "163px", md: "264px" }} bg="#D28C49" h="35px" py={2} borderRadius="8px">
              <Text
                color="white"
                fontSize="md"
                fontWeight={700}
                textAlign="center"
                mx="auto"
              >
                PLATINUM
              </Text>
            </HStack>
          </Th>
        </Tr>
      </Thead>
    </>
    <Tbody>
      {benefits.map((benefit) => (
        <Tr key={benefit.id}>
          <Td p={0} pl={4}>
            <HStack h="80px" spacing="3">
              <Box>
                <Text fontSize="sm" fontWeight={700}>
                  {benefit.name}
                </Text>
              </Box>
            </HStack>
          </Td>
         <Td px={{ base: 0, md:  2 }}>
            <Text fontSize="sm" textAlign={"center"} fontWeight={400}>
              {benefit.silver}
              <br />
              {benefit.silverammount}
            </Text>
          </Td>
         <Td px={{ base: 0, md:  2 }}>
            <Text fontSize="sm" textAlign="center" fontWeight={400}>
              {benefit.gold}
              <br />
              {benefit.goldammount}
            </Text>
          </Td>
         <Td px={{ base: 0, md:  2 }}>
            <Text fontSize="sm" textAlign="center" fontWeight={400}>
              {benefit.platinum}
              <br />
              {benefit.platinumammount}
            </Text>
          </Td>
        </Tr>
      ))}
    </Tbody>
  </Table>
);
