// loyalty
const faq = [
  {
    question: "What are the benefits of joining the loyalty program?",
    answer: "Collect points effortlessly, access exclusive tier rewards, benefit from up to 3% cashback, and enjoy up to 9% discounts and special gifts."
  },
  {
    question: "How do I accumulate points?",
    answer: "Simply shop with us as usual. There's no extra effort required on your part. Our system automatically tallies your points."
  },
  {
    question: "What do my points equate to in percentages?",
    answer: "Your cashback percentage directly corresponds to your membership tier. Silver tier members receive 1% cashback, Gold members enjoy 2%, and Platinum members benefit from 3% cashback on all purchases."
  },
  {
    question: "How do I redeem my rewards?",
    answer: "Redeeming your points is straightforward. Every 100 points you've accumulated translates to $1 off your purchases. You can apply these points at checkout for instant discounts on your purchases."
  },
  {
    question: "Can I opt out of the Loyalty Program if I change my mind?",
    answer: "Yes, your satisfaction is our top priority. If you decide our Loyalty Program isn't right for you, you can opt out easily. Please note that if you opt out without redeeming your accumulated points, they will be forfeited."
  },
  {
    question: "How do I keep track of my points and rewards?",
    answer: "Your current points balance and rewards tier can be easily viewed in your account dashboard."
  },
  {
    question: "Can I use my points to purchase anything from your store?",
    answer: "Yes, points can be applied as discounts towards any purchase."
  },
  {
    question: "Do my points ever expire?",
    answer: "Points will expire if there is no account activity for 12 months. We encourage regular purchases or point redemptions to keep your account active and your points valid."
  },
  {
    question: "Is there a cap on the number of points I can earn?",
    answer: "There is no cap on the number of points you can earn. The more you shop, the more rewards you can unlock."
  }
];



export default faq
